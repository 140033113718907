import { useState } from "react"
import { AppContext } from "./AppContext"
import { ConfiguracoesProps } from "./hooks/useConfiguracoes"


interface AppProviderProps {
    children: JSX.Element | JSX.Element[]
}

export const AppProvider = ({ children }: AppProviderProps) => {
    const [configuracoes, setConfiguracoes] = useState<ConfiguracoesProps>()

    return (
        <AppContext.Provider value={{ configuracoes, setConfiguracoes }}>
            {children}
        </AppContext.Provider>
    )
}
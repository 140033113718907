import { createGlobalStyle } from 'styled-components';



export default createGlobalStyle` 
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    --primary_color: ${process.env.NEXT_PUBLIC_PRIMARY_COLOR};
    --secondary_color: ${process.env.NEXT_PUBLIC_SECONDARY_COLOR};
    --tertiary_color: ${process.env.NEXT_PUBLIC_TERTIARY_COLOR};
    --font_menu_color: ${process.env.NEXT_PUBLIC_FONTE_MENU_COLOR};
    --url_background_topo: url( ${encodeURI(process.env.NEXT_PUBLIC_URL_BACKGROUND_TOPO)});
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif !important;
    color: #444444;
  }

  a {
    color: #444444;
    text-decoration: none;
  }

  a:hover {
    color: #444444;
    text-decoration: none;
  }

  .mapLink {
    color: #1890ff;
    text-decoration: none;
  }

  .mapLink:hover {
    color: #99ceff;
    text-decoration: none;
  }

  html, body, #root {
    height: 100%;
    scroll-behaviour: smooth;
  }

  .ant-select-selection-placeholder {
    color: #999999 !important
  }

  .ant-carousel .slick-dots {
    z-index: 5 !important;
  }

  .ant-input, .ant-picker, .ant-input-affix-wrapper, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper {
    outline: 0;
    border-radius: 4px;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    top: -5px !important;
  }

  .ant-input-group-addon {
    background-color: transparent !important;
  }

  .ant-input-search-button {
    height: 38px;
  }

  .shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  }

  .shadow-none {
    box-shadow: none!important;
  }

  .shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  }

  .ui.blue.progress .bar {
    background-color: #2CA8FF !important;
  }

  .ui.progress:first-child {
    margin: 8px 0;
  }

  .ant-form-item .ant-input-textarea-show-count::after {
    bottom: -28px !important;
  }

  .ant-result-title {
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    line-height: 64px;
  }
  
  .ant-result-subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }



.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: 400 !important;
}

::-webkit-input-placeholder { /* Edge */
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}

:-ms-input-placeholder { /* Internet Explorer */
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}

::placeholder {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}

select:required:invalid {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}

.ant-input:focus, .ant-input-focused, .ant-select-focused, .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgb(255 255 255 / 20%) !important;
  outline: 0;
  border:none;
}

.ant-input:hover, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  outline: 0;
  border:none;
  border-bottom: 1.2px solid #2185d0;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, .ant-input-affix-wrapper:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  box-shadow: 0 0 0 2px rgb(255 255 255 / 20%) !important;
  outline: 0;
  border:none;
  border-bottom: 1.2px solid #e5e7eb;
}


.ui.blue.button, .ui.blue.buttons .button {
  background-color: #3192D1;
} 

.ui.white.button, .ui.white.buttons .button {
  background-color: #FFF;
  color: var(--primary_color);
}

.card .card-header {
  padding: 15px 15px 0;
  border: 0;
  margin-bottom: 0px;
  background-color: rgb(255, 255, 255) !important;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
    margin-top: 0;
}

.empreendimentoName {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .empreendimentoName {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
}

.card {
  border: 0 !important;
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
}

.card:hover {
  box-shadow: 0 1px 10px 1px rgba(39, 39, 39, 0.4);
}

.card-transp {
  background-color: rgba(255,255,255,1) !important;
  border-radius: 14px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.cardPublico {
  background-color: transparent !important;
}

.ant-table-tbody > tr {
  margin-bottom: 5px;
}

  .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 6px 16px !important;
  }

  .item-header {
    max-width: 100%;
    min-height: 1px;
    position: relative;
    box-sizing: border-box;
  }

  .grid-view .item-wrap.item-wrap-no-frame {
    padding: 0;
  }

  .grid-view .item-wrap {
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
  }

  .grid-view .item-listing-wrap {
    width: 50%;
    flex-basis: 50%;
    -ms-flex-preferred-size: auto !important;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }

  .grid-view .d-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .grid-view .labels-wrap {
    top: 17px;
    right: 20px;
    z-index: 1;
    position: absolute;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-font-smoothing: subpixel-antialiased !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .label {
    font-size: 12px; 
    line-height: 11px; 
    font-weight: 500; 
    margin: 0;
    text-transform: uppercase;
    padding: 6px 10px; 
    color: #fff;
    background-color: rgba(31, 122, 105, 0.8);
    border-radius: 2px;
  }

  .btn-detail {
    margin: 20px 0;
    font-weight: 600;
  }

  .btn-detail:hover{
    color: rgba(31, 122, 105, 0.8)
  }
  
  .item-price-wrap {
    bottom: 10px;
    left: 20px;
    color: #fff;
    padding: 0;
    margin: 0;
    font-weight: 900;
    list-style: none;
    z-index: 2;
    position: absolute;
  }

  .item-price-wrap .item-price {
    font-size: 22px;
    position: relative;
    box-sizing: border-box;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .item-tools {
    bottom: 20px;
    right: 20px;
    list-style: none;
  }

  .item-tool > span {
    color: #fff;
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, 0.35);
    cursor: pointer;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    display: inline-block;
  }

  .grid-view .item-wrap.item-wrap-no-frame .hover-effect {
    max-height: 250px;
    border-radius: 4px 4px 0 0;
    box-shadow: none;
    text-decoration: none;
    box-sizing: border-box;
  }

  .hover-effect, .hover-effect-flat {
    cursor: pointer;
    overflow: hidden;
    transition: 0.2s;
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    transition: 0.2s;
    position: relative;
  }

  /* .hover-effect:before {
    opacity: 1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(0%, rgba(0, 0, 0, 0)), color-stop(50%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.45)));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.45) 100%);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.45) 100%);
  } */

  .hover-effect:before, .hover-effect-flat:before {
    content: "";
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    position: absolute;
    transition: 0.2s;
    height: 100%;
    width: 100%;
  }
  
  .hover-effect img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
  }

  .loginMessage h1 {
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    line-height: 64px;
  }

  .ant-back-top {
    right: 20px !important;
    bottom: 55px !important;
  }

  #components-back-top-demo-custom .ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

  .whatsapp-default, .whatsapp-venda {
    background-color: #2dce89;
    color: #FFF;
    font-size: 20px;
    position: fixed;
    z-index: 999;


    display: inline-block;
    padding: 4px;
    border-radius: 4px;
    transition: 0.5s;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  }

  .whatsapp-default:hover, .whatsapp-venda:hover {
    background: #25a76f;
    color: #fff;
  }

  .whatsapp-default {
    right: 20px;
    bottom: 100px !important;
  }

  .whatsapp-venda {
    right: 20px;
    bottom: 145px !important;
  }

  .loginMessage h2{
    font-weight: 600;
    font-size: 25px;
    line-height: 48px;
    margin: 10px 0 0;
  }

  .loginMessage h6{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .loading {
    float: left;
    width: 1.2em;
    height: 1.2em;
    -webkit-animation: button-spin .6s linear;
    animation: button-spin .6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: .2em;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
  }

  .ui.button:disabled, .ui.buttons .disabled.button, .ui.disabled.active.button, 
  .ui.disabled.button, .ui.disabled.button:hover {
    opacity: 0.7!important;
  }

  .single-caracter {
    min-height: 140px;
  }

  .container-imovel-item  { 
    padding-top: 15px;
    padding-left: 5px;
    font-size: 12px;
  }

  .card-imovel-item {
    padding: 5px;
    display: inline-flex !important;
    width: 42px;
    height: 42px;
    font-size: 22px;
    margin: 5px;
    border-radius: .6rem;
    background-color: #fff;
  }

  @media (max-width: 740px) {
    .card-imovel-item {
      margin-top: .4rem !important;
    }
  }

  /* .blurring.dimmable > .dimmer {
    background-color: rgba(0, 0, 0, 0.8) !important;
  } */


  .ui.basic.modal > .header {
    color: #f4873e !important;
  }

  .ui.basic.green.button {
    color: #2dce89 !important;
    border-color: #2dce89 !important;
  }

  .ui.button {
    font-weight: normal !important;
  }

  .ui.fullscreen.modal,
  .ui.fullscreen.scrolling.modal {
    left: auto !important;
  }

  .ui.message {
    font-size: 12px !important;
  }

  .ui.buttons .or:before {
    content: "ou" !important;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message, 
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 80px !important;
  }

  .titleLogin{
    font-weight: 600;
    font-size: 25px;
    line-height: 48px;
    margin: 10px 0 0;
  }

  .divClick {
    cursor: pointer;
  }

   .wizard.wizard-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
    background-color: var(--secondary_color);
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: var(--secondary_color) ;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    margin-right: 3px;
    flex-grow: 1;
  }

  .disabledTabs{
    background-color: #808080 !important;
    color: #B3B3B3 !important;
  }

  .disabledTabNumber {
    color: #FFF !important;
    border: 1px solid #B3B3B3 !important;
  }

  .disabledTabLabel {
    color: #FFF !important;
  }

  .linkTable {
    cursor: pointer;
  }

  .linkTable:hover {
    color: #4DA6FF;
  }

  .scrollTop {
    position: fixed;
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
  }

  .scrollTop:hover{
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  .avatar-uploader > .ant-upload {
    width: 100% !important;
    height: auto !important;
    min-height: 178px;
  }

  .avatar-uploader > .ant-upload > span {
    min-height: 178px;
  }

  .etapas-nav .etapas-steps {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
  }

  .etapas-nav .etapas-steps .etapas-step {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin-right: 1rem;
  }


  .etapas-nav .etapas-steps .etapas-step .etapas-label {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 2rem .5rem;
  }

  .etapas-nav[data-wizard-clickable=true] .etapas-step {
      cursor: pointer;
  }

  .etapas-nav .etapas-steps .etapas-step .etapas-label .etapas-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    color: #b5b5c3 ;
  }

  .etapas-nav .etapas-steps .etapas-step[data-wizard-state=current] .etapas-label .etapas-title {
    color: #4DA6FF !important;
  }


  .etapas-nav .etapas-steps .etapas-step .etapas-label .etapas-bar {
    height: 10px;
    width: 100%;
    background-color: #ebedf3;
    border-radius: .25rem;
    position: relative;
  }

  .etapas-nav .etapas-steps .etapas-step[data-wizard-state=current] .etapas-label .etapas-bar:after {
    width: 100%;
    background-color: #4DA6FF;
    border-radius: .25rem;
  }

  .etapas-nav .etapas-steps .etapas-step .etapas-label .etapas-bar:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 0;
    background-color: transparent;
    -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
}

@media (min-width: 992px) {
    .pl-lg-15, .px-lg-15 {
        padding-left: 3.75rem!important;
    }

    .pr-lg-15, .px-lg-15 {
      padding-right: 3.75rem!important;
    }

    .pb-lg-3, .py-lg-3 {
      padding-bottom: .75rem!important;
    }
  }

  @media (max-width: 991.98px) {
    .etapas-nav .etapas-steps {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .etapas-nav .etapas-steps .etapas-step {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      position: relative;
      width: 100%;
    }

    .etapas-nav .etapas-steps .etapas-step .etapas-label {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      padding: 1rem 0;
    }
  }


  .icons-items i {
    display: inline-flex !important;
    width: 42px;
    height: 42px;
    font-size: 22px;
    margin: 5px 15px 5px 5px;
    border-radius: .6rem;
    background-color: #fff;
    box-shadow: 3px 3px 6px 0 rgb(28 49 85 / 8%);
    color: #f80;
  }

  .symbol {
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    border-radius: .85rem;
  }

  .symbol-basic {
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
  }

  .symbol .symbol-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 500;
    color: #808080;
    background-color: #f3f6f9;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: .85rem;
  }

  .symbol.symbol-50 .symbol-label {
    width: 70px;
    height: 70px;
  }

   .symbol.symbol-50 .symbol-progress {
    width: 80px;
    height: 80px;
  }


  .symbol.symbol-light .symbol-label {
    background-color: #f3f6f9;
    color: #7e8299;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  .symbol>img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: .85rem;
  }

  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }

  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }

  .symbol-basic>img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    padding: 2px;
  }

  .symbol.symbol-50>img {
      width: 100%;
      max-width: 50px;
      height: 50px;
  }

  .img-carousel {
    border-top-left-radius: .85rem;
    border-bottom-left-radius: .85rem;
  }

  .ui.progress:last-child {
    margin-bottom: 0.5em !important;

}

  @media (min-width: 992px) {
    .symbol>img {
        width: 100%;
        max-width: 50px;
        height: 50px;
    }

    .symbol-basic.symbol-lg-120>img {
      width: 100%;
      /* max-width: 120px; */
      height: 120px;
    }

    .symbol-basic.symbol-lg-150>img {
      width: 100%;
      /* max-width: 150px; */
      height: 150px;
    }

    .symbol-basic.symbol-lg-180>img {
      width: 100%;
      /* max-width: 150px; */
      height: 300px;
    }
  }

  @media (min-width: 768px) {
    .symbol .symbol-label {
      width: 50px;
      height: 50px;
    }

    .symbol>img {
      width: 100%;
      max-width: 50px;
      height: 50px;
    }
  }

  @media (min-width: 576px) {
    .symbol .symbol-label {
      width: 50px;
      height: 50px;
    }

    .symbol>img {
      width: 100%;
      max-width: 50px;
      height: 50px;
    }
  }


  .font-size-lg {
    font-size: 1.08rem;
  }

  .text-dark-75 {
    color: #3f4254!important;
  }

  .wizard[data-wizard-clickable=true] .wizard-step {
    cursor: pointer;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      color: #FFF;
      padding: 1.5rem;
  }

  .wizard-desc {
    font-size: 11px;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-number {
    font-size: 1rem;
    font-weight: 600;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 2.75rem;
    flex: 0 0 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    color: var(--primary_color);
    margin-right: 1rem;
    border-radius: 4px;
    border: 1px solid #808080;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-wrapper .wizard-label .wizard-title {
    color: var(--primary_color);
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .wizard-label .wizard-title {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }

  .wizard[data-wizard-clickable=true] .wizard-step {
      cursor: pointer;
  }

  .container { 
    max-width: 90vw !important;
  }

  @media (max-width: 767.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
      -webkit-box-flex: 0!important;
      -ms-flex: 0 0 100%!important;
      flex: 0 0 100%!important;
      position: relative;
      width: 100%!important;
    }
  }
`;
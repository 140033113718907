import Head from 'next/head';
import { useEffect } from 'react';


import { BackTop, ConfigProvider } from 'antd';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import GlobalStyle from '@assets/styles/global';
import ptBR from 'antd/lib/locale/pt_BR';

import '@assets/styles/aos/aos.css';
import '@assets/styles/bootstrap-icons/bootstrap-icons.css';
import '@assets/styles/glightbox/css/glightbox.min.css';
import '@assets/styles/remixicon/remixicon.css';
import '@assets/styles/swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';

import '@assets/styles/style.css';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'semantic-ui-css/semantic.min.css';

import { AppProvider } from 'contexts/AppProvider';
import moment from 'moment';
import 'moment/locale/pt-br';
import Script from 'next/script';
import { Icon } from 'semantic-ui-react';
moment.locale('pt-br');

function Noop({ children }) {
  return <>{children}</>;
}

const GA_ID = process.env.NEXT_PUBLIC_GA_ID;
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export const GApageview = (url) => {
  (window as any).dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

const GTMpageview = (url) => {
  if (
    window &&
    window.location &&
    window.location.pathname &&
    (window as any)?.gtag
  ) {
    (window as any)?.gtag('config', GA_ID, {
      page_path: url,
    });
  }
};

const GTMevent = ({ action, category, label, value }) => {
  (window as any).gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const Layout = (Component as any).Layout || Noop;
  const colorPrimary = process.env.NEXT_PUBLIC_PRIMARY_COLOR;
  const router = useRouter();

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FB_ID); // facebookPixelId
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      GApageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      GTMpageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <GlobalStyle />
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href={process.env.NEXT_PUBLIC_FAVICON} />

        <script type="text/javascript" src="/static/aos.js"></script>
        <script type="text/javascript" src="/static/glightbox.min.js"></script>
        <script
          type="text/javascript"
          src="/static/isotope.pkgd.min.js"></script>
        <script
          type="text/javascript"
          src="/static/swiper-bundle.min.js"></script>
        <script type="text/javascript" src="/static/main.js"></script>
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
        />
      </Head>
      <ConfigProvider locale={ptBR}>
        <AppProvider>
          <Layout pageProps={{ ...pageProps }}>
            <Component {...pageProps} />
          </Layout>
        </AppProvider>
      </ConfigProvider>
      <BackTop>
        <div
          style={{
            height: 40,
            width: 40,
            lineHeight: '40px',
            borderRadius: 4,
            backgroundColor: colorPrimary,
            color: '#FFF',
            textAlign: 'center',
            justifyContent: 'center',
            fontSize: 14,
          }}>
          <Icon style={{ paddingLeft: '4px' }} size="big" name="caret up" />
        </div>
      </BackTop>
    </>
  );
}

export default MyApp;

import { createContext, useContext } from "react";
import { ConfiguracoesProps } from "./hooks/useConfiguracoes";


export type AppContextProps = {
    configuracoes: ConfiguracoesProps,
    setConfiguracoes: (configuracoes: ConfiguracoesProps) => void
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps)

export function useAppContexto() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useMeuContexto deve ser usado dentro de um MeuContextoProvider');
    }
    return context;
}